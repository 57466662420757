.revamp-table.ReactTable {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.revamp-table.ReactTable * {
	box-sizing: border-box
}

.revamp-table.ReactTable .rt-table {
	-webkit-box-flex: 1;
	-ms-flex: auto 1;
	flex: auto 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
	border-collapse: collapse;
	/* overflow: unset !important; */
}

.full-revamp-table.ReactTable .rt-table {
	overflow: unset !important;
}

.revamp-table.ReactTable .rt-thead {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
    border-bottom: 1px solid #CFD8E6 ;
}

.revamp-table.ReactTable .rt-thead.-headerGroups {
	border: 0;
    display: none;
}

.revamp-table.ReactTable .rt-thead.-filters {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.revamp-table.ReactTable .rt-thead.-filters input,
.revamp-table.ReactTable .rt-thead.-filters select {
	border: 1px solid rgba(0, 0, 0, 0.1);
	background: #fff;
	padding: 5px 7px;
	font-size: inherit;
	border-radius: 4px;
	font-weight: normal;
	outline-width: 0
}

.revamp-table.ReactTable .rt-thead.-filters .rt-th {
	border-right: 1px solid rgba(0, 0, 0, 0.02)
}

.revamp-table.ReactTable .rt-thead .rt-th,
.revamp-table.ReactTable .rt-thead .rt-td {
	padding: 12px 8px;
	line-height: normal;
	position: relative;
	transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
	box-shadow: inset 0 0 0 0 transparent;
}

.revamp-table.ReactTable .rt-thead .rt-th.-sort-asc,
.revamp-table.ReactTable .rt-thead .rt-td.-sort-asc {
	box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6)
}

.revamp-table.ReactTable .rt-thead .rt-th.-sort-desc,
.revamp-table.ReactTable .rt-thead .rt-td.-sort-desc {
	box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6)
}

.revamp-table.ReactTable .rt-thead .rt-th.-cursor-pointer,
.revamp-table.ReactTable .rt-thead .rt-td.-cursor-pointer {
	cursor: pointer
}

.revamp-table.ReactTable .rt-thead .rt-th:last-child,
.revamp-table.ReactTable .rt-thead .rt-td:last-child {
	border-right: 0
}

.revamp-table.ReactTable .rt-thead .rt-th:focus {
	outline-width: 0
}

.revamp-table.ReactTable .rt-thead .rt-resizable-header {
	overflow: visible;
}

.revamp-table.ReactTable .rt-thead .rt-resizable-header:last-child {
	overflow: hidden
}

.revamp-table.ReactTable .rt-thead .rt-resizable-header-content {
	font-family: 'Montserrat';
	font-style: normal;
	font-size: 14px;
    font-weight: 700;
	line-height: 17px;
	color: #000000;
	opacity: 0.8;
	
}

.revamp-table.ReactTable .rt-thead .rt-header-pivot {
	border-right-color: #f7f7f7
}

.revamp-table.ReactTable .rt-thead .rt-header-pivot:after,
.revamp-table.ReactTable .rt-thead .rt-header-pivot:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none
}

.revamp-table.ReactTable .rt-thead .rt-header-pivot:after {
	border-color: rgba(255, 255, 255, 0);
	border-left-color: #fff;
	border-width: 8px;
	margin-top: -8px
}

.revamp-table.ReactTable .rt-thead .rt-header-pivot:before {
	border-color: rgba(102, 102, 102, 0);
	border-left-color: #f7f7f7;
	border-width: 10px;
	margin-top: -10px
}

.revamp-table.ReactTable .rt-tbody {
	-webkit-box-flex: 99999;
	-ms-flex: 99999 1 auto;
	flex: 99999 1 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow: unset !important;
}


.revamp-table.ReactTable .rt-tbody .rt-tr-group {
	border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.revamp-table.ReactTable .rt-tbody .rt-tr-group:last-child {
	border-bottom: 0
}


.revamp-table.ReactTable .rt-tbody .rt-expandable {
	cursor: pointer;
	text-overflow: clip
}

.revamp-table.ReactTable .rt-tr-group {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch
}

.revamp-table.ReactTable .rt-tr {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex
}

.revamp-table.ReactTable .rt-th,
.revamp-table.ReactTable .rt-td {
	font-weight: 500;
	font-style: normal;
	font-size: 14px;
	-webkit-box-flex: 1;
	-ms-flex: 1 0 0px;
	flex: 1 0 0;
	white-space: nowrap;
	text-overflow: ellipsis;

	transition: .3s ease;
	transition-property: width, min-width, padding, opacity;
}

.revamp-table.ReactTable .rt-th.-hidden,
.revamp-table.ReactTable .rt-td.-hidden {
	width: 0 !important;
	min-width: 0 !important;
	padding: 0 !important;
	border: 0 !important;
	opacity: 0 !important
}

.revamp-table.ReactTable .rt-expander {
	display: inline-block;
	position: relative;
	margin: 0;
	color: transparent;
	margin: 0 10px;
}

.revamp-table.ReactTable .rt-expander:after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) rotate(-90deg);
	transform: translate(-50%, -50%) rotate(-90deg);
	border-left: 5.04px solid transparent;
	border-right: 5.04px solid transparent;
	border-top: 7px solid rgba(0, 0, 0, 0.8);
	transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
	cursor: pointer
}

.revamp-table.ReactTable .rt-expander.-open:after {
	-webkit-transform: translate(-50%, -50%) rotate(0);
	transform: translate(-50%, -50%) rotate(0)
}

.revamp-table.ReactTable .rt-resizer {
	display: inline-block;
	position: absolute;
	width: 36px;
	top: 0;
	bottom: 0;
	right: -18px;
	cursor: col-resize;
	z-index: 10
}

.revamp-table.ReactTable .rt-tfoot {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}



.revamp-table.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
	background: rgba(0, 0, 0, 0.05)
}

.revamp-table.ReactTable .-pagination {
	z-index: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 3px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
	border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.revamp-table.ReactTable .-pagination input,
.revamp-table.ReactTable .-pagination select {
	border: 1px solid rgba(0, 0, 0, 0.1);
	background: #fff;
	padding: 5px 7px;
	font-size: inherit;
	border-radius: 4px;
	font-weight: normal;
	outline-width: 0
}

.revamp-table.ReactTable .-pagination .-btn {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	display: block;
	width: 100%;
	height: 100%;
	border: 0;
	border-radius: 4px;
	padding: 6px;
	font-size: 1em;
	color: rgba(0, 0, 0, 0.6);
	background: rgba(0, 0, 0, 0.1);
	transition: all .1s ease;
	cursor: pointer;
	outline-width: 0;
}

.revamp-table.ReactTable .-pagination .-btn[disabled] {
	opacity: .5;
	cursor: default
}

.revamp-table.ReactTable .-pagination .-btn:not([disabled]):hover {
	background: rgba(0, 0, 0, 0.3);
	color: #fff
}

.revamp-table.ReactTable .-pagination .-previous,
.revamp-table.ReactTable .-pagination .-next {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	text-align: center
}

.revamp-table.ReactTable .-pagination .-center {
	-webkit-box-flex: 1.5;
	-ms-flex: 1.5;
	flex: 1.5;
	text-align: center;
	margin-bottom: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: distribute;
	justify-content: space-around
}

.revamp-table.ReactTable .-pagination .-pageInfo {
	display: inline-block;
	margin: 3px 10px;
	white-space: nowrap
}

.revamp-table.ReactTable .-pagination .-pageJump {
	display: inline-block;
}

.revamp-table.ReactTable .-pagination .-pageJump input {
	width: 70px;
	text-align: center
}

.revamp-table.ReactTable .-pagination .-pageSizeOptions {
	margin: 3px 10px
}

.-pagination-bottom{
	display: flex;
	justify-content: space-between;
}

.revamp-table.ReactTable .Table__pagination select {
    background: #fff;
    padding: 2px;
    font-size: inherit;
    border: 1px solid #CFD8E6;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    font-weight: 400;
}

.revamp-table.ReactTable .Table__pagination select option {
    background: #fff;
    padding: 12px;
    font-size: inherit;
    border: 1px solid #CFD8E6;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    font-weight: 400;
}

.revamp-table.ReactTable .rt-noData {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	background: rgba(255, 255, 255, 0.8);
	transition: all .3s ease;
	z-index: 1;
	pointer-events: none;
	padding: 20px;
	color: rgba(0, 0, 0, 0.5)
}

.revamp-table.ReactTable .-loading {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.8);
	transition: all .3s ease;
	z-index: -1;
	opacity: 0;
	pointer-events: none;
}

.revamp-table.ReactTable .-loading>div {
	position: absolute;
	display: block;
	text-align: center;
	width: 100%;
	top: 50%;
	left: 0;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.6);
	-webkit-transform: translateY(-52%);
	transform: translateY(-52%);
	transition: all .3s cubic-bezier(.25, .46, .45, .94)
}

.revamp-table.ReactTable .-loading.-active {
	opacity: 1;
	z-index: 2;
	pointer-events: all;
}

.revamp-table.ReactTable .-loading.-active>div {
	-webkit-transform: translateY(50%);
	transform: translateY(50%)
}

.revamp-table.ReactTable .rt-resizing .rt-th,
.revamp-table.ReactTable .rt-resizing .rt-td {
	transition: none !important;
	cursor: col-resize;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.Table__pagination {
	display: flex;
	justify-content: space-between;
	padding: 20px 10px;
  }
  
  .Table__pageButton {
	font-size: 18px;
	font-weight: 700;
	cursor: pointer;
	width: 75px;
	height: 32px;
	border-radius: 50%;
	color: #172b4d;
	
	line-height: 32px;
	display: inline-block;
	text-align: center;
  }
  
  .Table__pageButton:disabled {
	cursor: not-allowed;
	color: gray;
  }
  
  .Table__pageButton--active {
	color: white;
	background: #714fff;
	font-weight: bold;
  }
  ReactTable .rt-tbody .rt-td {
	border-right: 2px solid #e8e5f7;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 18px;
	color: #dedeeb;
  }
  
  .revamp-table.ReactTable {
	position: relative;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	border: transparent;
  }
  
  .revamp-table.ReactTable .rt-thead .rt-th,
  .revamp-table.ReactTable .rt-thead .rt-td {
	border-right: 2px solid #e8e5f7;
  }
  
  .revamp-table.ReactTable .rt-thead.-filters .rt-th {
	border-right: 2px solid #e8e5f7;
	padding: 7px;
  }
  
  .revamp-table.ReactTable .rt-thead {
	-webkit-box-flex: 1;
	flex: 1 0 auto;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-user-select: none;
	user-select: none;
  }
  
  .revamp-table.ReactTable .rt-tbody .rt-td {
	border-right: 2px solid #e8e5f7;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #3a3a49;
	
  }

  
  .revamp-table.ReactTable .rt-tbody .rt-tr-group {
	border-bottom: solid 1px #e8e5f7;
  }
  
 
  
  .revamp-table.ReactTable .rt-expander:after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) rotate(-90deg);
	transform: translate(-50%, -50%) rotate(-90deg);
	border-left: 5.04px solid transparent;
	border-right: 5.04px solid transparent;
	border-top: 7px solid #714fff;
	transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	cursor: pointer;
  }
  
  .revamp-table.ReactTable .rt-expander.-open:after {
	-webkit-transform: translate(-50%, -50%) rotate(0);
	transform: translate(-50%, -50%) rotate(0);
  }
  

.Table__nextPageWrapper {
    margin-left: 20px;
	margin-top: 5px;
}
.Table__prevPageWrapper {
	margin-right: 10px;
	margin-top: 5px;
}


.revamp-table.ReactTable .rt-tbody .rt-expandable{
	align-content: center;
}
.button{
	margin: none !important;
	padding: none !important;
}
.react-select-1 > div{
	height: 0px;
	width: 100%;
	

}
.react-select-2 > div{
	height: 0px;
	border-radius: 16px;
    background: #fff;

}
.modal-content{
	border-radius: 10px !important;
	border-color: #F2F1F6 !important;
  }
.modal-dialog {
	min-width: 616px !important;
  }
.revamp-modal-header .modal-header{
	padding: none !important;
	border-radius: 10px !important;
  }
 .revamp-modal-header .modal-title{
	font-size: 16px;
	font-weight: 600;
	font-family: Inter, sans-serif; 
	color:#714FFF
  }
  .ant-input-affix-wrapper{
	padding: 4px 4px;
  }
  .revamp-modal-header {
    padding: 1rem !important;
}
.custom-toast {
	background-color: #F5F4FF !important;
	border-radius: 10px !important;
	/* padding: 16px 10px !important; */
	color: black !important;
	font-family: 'Arial', sans-serif;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .custom-toast .Toastify__close-button {
	color: #FF754C !important; /* Red color for the close button */
	font-size: 20px !important;
  }
  .marquee {
	width: 50%;
	overflow: hidden;
	/* position: relative; */
  }
  
  .marquee span {
	display: block;
	width: 100%;
	/* height: 50px; */
	/* position: absolute; */
	overflow: hidden;
	animation: marquee 7s linear infinite;
  }
  
  @keyframes marquee {
	0% { transform: translateX(100%); }
	100% { transform: translateX(-100%); }
  }